import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Ascend',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
    description: `Ascend Prime is the premier Steak and Sushi restaurant in Bellevue Washington. I was tasked with designing a web experience that mirrored the experience of the restaurant. As you move through the space you really move through different enviornments. Going from light to dark, gold to rustic and vintage to luxury. The space was inspired by a combination of Tom Ford and Louis Vuitton. This was a challenging project as the restaurant is very large and the menu is vast. We also executed a video experience to highlight the space as well as their excellent private dining spaces. Corporate dining is a large part of their business so my team and I wanted to show that regardless of your corporate budget their is a space that will fit your needs. We also were able to utilize the private soundtrack that was developed for the restaurants playlist which was a cool and unique challenge.`,
  },
  caseId: 'ascend',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/rb16vfap-OmmZTDe4.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/rb16vfap-1920.jpg',
      },
    },
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
